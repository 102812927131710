import { IonTabButton, IonToolbar, IonFooter, IonTabBar, IonIcon } from '@ionic/angular/standalone';
import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { addIcons } from 'ionicons';
import { homeOutline, peopleOutline, playOutline, personOutline, home, people, play, person, football, footballOutline } from 'ionicons/icons';

@Component({
  selector: 'cy-footer-menu',
  templateUrl: './footer-menu.component.html',
  styles: [
    `:host { display: contents }`
  ],
  standalone: true,
  imports: [
    IonTabBar,
    IonFooter,
    IonToolbar,
    IonTabButton, 
    IonIcon,
    CommonModule,
    RouterModule,
  ],
})
export class CYFooterMenuComponent implements OnInit {

  private activeButton: HTMLElement | null = null;

  @Input() active?: string;

  constructor(
    private el: ElementRef
  ) {
    addIcons({ homeOutline, peopleOutline, playOutline, personOutline, home, people, play, person, football, footballOutline })
  }

  ngAfterViewInit() {
    setTimeout(() => {
      
      const buttons = this.el.nativeElement.querySelectorAll('.menu__item');
  
      buttons.forEach((item: HTMLElement) => {
        const text = item.querySelector('.menu__text') as HTMLElement;
        this.setLineWidth(text, item);
  
        window.addEventListener('resize', () => {
          this.setLineWidth(text, item);
        });
      });
    }, 200);
  }

  private setLineWidth(text: HTMLElement, item: HTMLElement) {
    const lineWidth = text.offsetWidth + 'px';
    item.style.setProperty('--lineWidth', lineWidth);
  }

  private handleTransition(item: HTMLElement, text: HTMLElement) {
    item.addEventListener('transitionend', (e: TransitionEvent) => {
      if (e.propertyName !== 'flex-grow' || !item.classList.contains('active')) return;
      text.classList.add('active');
    });
  }

  ngOnInit() {}
}